import { SearchIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";

const SearchBox = ({
  text,
  onSubmit,
  error = false,
}: {
  text: string;
  onSubmit: (query: string) => void;
  error?: boolean;
}) => {
  const [inputValue, setInputValue] = useState(text);

  useEffect(() => {
    setInputValue(text);
  }, [text]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(inputValue);
      }}
    >
      <div className={"mt-1 flex"}>
        <label className="sr-only" htmlFor="search">
          Search for course
        </label>
        <Input
          id="search"
          className={
            "w-full !rounded-none !rounded-l-md " +
            (error ? "!border-red-600 focus:!ring-red-600" : "")
          }
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
          value={inputValue}
          placeholder="Search"
          autoComplete="off"
          type="search"
          name="search"
        />
        <Button
          type="submit"
          className="font-medium !-ml-px !rounded-none !rounded-r-md flex items-center space-x-2"
        >
          <SearchIcon className="h-5 w-5" aria-hidden="true" />
          <span className="hidden sm:inline-block">Search</span>
        </Button>
      </div>
    </form>
  );
};

export { SearchBox };
