import Link, { LinkProps } from "next/link";
import React, { ReactNode } from "react";

export const ButtonLink = ({
  className,
  children,
  ...props
}: LinkProps & {
  children: ReactNode;
  className: string;
  target?: React.HTMLAttributeAnchorTarget;
}) => (
  <Link
    className={
      "inline-flex items-center px-3 py-2 border border-transparent shadow-md rounded-md \
            text-gray-600 bg-#[2f55d4] hover:bg-gray-700 hover:text-white \
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 " +
      className
    }
    {...props}
  >
    {children}
  </Link>
);
