import type { GetStaticProps } from "next";
import { useRouter } from "next/router";
import { useState } from "react";
import { SearchBox } from "../components/search/search_box";
import { ButtonLink } from "../components/ui/button_link";
import CertificateImage from "../public/certificate.png";
import ClassroomImage from "../public/classroom.png";
import ManLearningImage from "../public/man-learning.min.svg";
import CourseImage from "../public/online-course.png";
import { activeCategories, categoryConfigs } from "../lib/search/category";
import Image from "next/future/image";
import produce from "immer";
import { defaultQuery, stringify } from "../lib/search/parse_query_params";
import { FrontMatter, loadPostFrontMatter, postSlugs } from "../lib/blog";
import Link from "next/link";

const incentives = [
  {
    name: "Find MOOCs",
    description: (
      <>
        <p>
          A lot of top rated Universities offer free online courses with
          certificates and available to anyone to enroll.
        </p>
        <p>
          Get the best knowledge available from institutions without the cost of
          a university degree.
        </p>
      </>
    ),
    imageSrc: CourseImage,
  },
  {
    name: "Get critical career skills",
    description: (
      <>
        <p>
          Career developement usually comes with different skills set
          requirements. Explore the different courses to pivot and expand your
          career
        </p>
      </>
    ),
    imageSrc: CertificateImage,
  },
  {
    name: "Learn together",
    description: (
      <>
        <p>
          Join millions of people from around the world learning together.
          Online learning is as easy and natural as chatting with a group of
          friends.
        </p>
      </>
    ),
    imageSrc: ClassroomImage,
  },
];

interface Props {
  posts: Omit<FrontMatter, "date">[];
}

export const getStaticProps: GetStaticProps<Props> = async () => {
  const frontMatters = postSlugs
    .slice(0, 3)
    .map((slug) => loadPostFrontMatter(slug));

  return {
    props: {
      // trunk-ignore(eslint/@typescript-eslint/no-unused-vars)
      posts: frontMatters.map(({ date, ...props }) => ({
        ...props,
      })),
    },
  };
};

const Home = ({ posts }: Props) => {
  const [error, setError] = useState(false);
  const router = useRouter();

  return (
    <>
      <section className="w-full pt-32 lg:pb-12 bg-[#f5f6fd]">
        <div className="max-w-7xl mx-auto px-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div>
            <h1>
              <span className="mt-1 block text-center lg:text-left text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl mx-auto cursor-default">
                <span className="text-gray-700">Search for </span>
                <span className="text-blue-700">online courses </span>
                <span className="text-gray-700">across the internet</span>
              </span>
            </h1>
            <div className="mt-8 mx-auto">
              <SearchBox
                text=""
                error={error}
                onSubmit={(query) => {
                  if (query === "") {
                    setError(true);
                    return;
                  } else {
                    setError(false);
                    const request = produce(defaultQuery, (draft) => {
                      draft.searchTerm = query;
                    });
                    router.push(`/search?${stringify(request)}`);
                  }
                }}
              />
              <div className="relative mt-8">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="px-2 text-lg text-gray-500 bg-[#f5f6fd] cursor-default">
                    or browse by category
                  </span>
                </div>
              </div>
              <div className="inline-flex flex-wrap mt-4 gap-x-4 gap-y-4">
                {activeCategories.map((c) => {
                  const config = categoryConfigs[c];
                  return (
                    <ButtonLink
                      key={config.name}
                      href={{
                        pathname: "/category/[slug]",
                        query: { slug: config.slug },
                      }}
                      className="font-medium"
                    >
                      <div className="w-6 h-6 mr-2 relative">
                        <Image
                          src={config.img}
                          alt={`Icon for category ${config.name}`}
                        />
                      </div>
                      {config.name}
                    </ButtonLink>
                  );
                })}
              </div>
            </div>
          </div>
          <Image
            src={ManLearningImage}
            alt="Photo of a man studying online"
            className="p-12 mx-auto w-3/4 lg:w-full h-auto"
          />
        </div>
      </section>
      <section>
        <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3" />
          </div>
          <div className="relative max-w-7xl mx-auto">
            <div className="text-center">
              <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                <Link
                  href={{ pathname: "/blog/page/[page]", query: { page: 1 } }}
                >
                  From the <span className="underline">blog</span>
                </Link>
              </h2>
              <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                Get the latest information about online learning with classhubs.
              </p>
            </div>
            <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
              {posts.map((post) => (
                <div
                  key={post.title}
                  className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  <div className="flex-shrink-0">
                    <Image
                      className="h-48 w-full object-cover"
                      src={post.cover}
                      alt="cover image of post"
                      sizes="50vw"
                      width={500}
                      height={300}
                    />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <Link
                        href={{
                          pathname: "/blog/[slug]",
                          query: { slug: post.slug },
                        }}
                        className="block mt-2"
                      >
                        <p className="text-xl font-semibold text-gray-900">
                          {post.title}
                        </p>
                        <p className="mt-3 text-base text-gray-500 line-clamp-5">
                          {post.description}
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="max-w-7xl mx-auto">
        <div className="px-6 py-16 sm:p-16">
          <div className="max-w-xl mx-auto lg:max-w-none">
            <div className="text-center">
              <h2 className="text-4xl font-bold tracking-tight text-gray-700 cursor-default">
                Take the next step toward your personal and professional goals
              </h2>
            </div>
            <div className="mt-12 mx-auto grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
              {incentives.map((incentive) => (
                <div
                  key={incentive.name}
                  className="text-left flex flex-row items-center lg:flex-col lg:block lg:text-center"
                >
                  <div className="sm:flex-shrink-0">
                    <Image
                      src={incentive.imageSrc}
                      alt=""
                      className="flow-root w-16 h-auto mx-auto"
                    />
                  </div>
                  <div className="mt-3 sm:mt-0 ml-6 lg:mt-6 lg:ml-0 incentive cursor-default">
                    <h3 className="text-md font-medium text-gray-900">
                      {incentive.name}
                    </h3>
                    <div className="flex flex-col space-y-2 text-sm text-gray-500">
                      {incentive.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
