import { InputHTMLAttributes } from "react";

export const Input = ({
  className,
  ...props
}: InputHTMLAttributes<HTMLInputElement>) => (
  <input
    className={
      "rounded-md placeholder-gray-500 shadow-sm border-gray-400 focus:outline-none focus:ring-gray-800 focus:border-gray-800 focus:shadow-lg " +
      className
    }
    {...props}
  />
);
